// APP
import Vue from 'vue';

import store from './store';
import PortalVue from 'portal-vue';

// Import Shared
import '@shared/translations';
import '@shared/filters';
import '@shared/validate';
import '@shared/components';

import router from './router';
import './registerServiceWorker';
import VueSanitize from 'vue-sanitize';
import App from './App.vue';
import { ignoredElements } from './talentechImport';
import { log, SEVERITY } from '@/utils/logging';

Vue.config.productionTip = process.env.NODE_ENV === 'production';

// Portal
Vue.use(PortalVue);

// XSS
let defaults = VueSanitize.defaults;
// Allow images/iframe and setting some limitation to the attributes, thus preventing XSS attacks but allowing img/iframes
defaults.allowedTags = [...defaults.allowedTags, ...['img', 'iframe']];
defaults.allowedAttributes['iframe'] = ['src', 'allowfullscreen', 'frameborder', 'height', 'width'];
if (defaults.allowedAttributes['a']) {
  defaults.allowedAttributes['a'] = [...defaults.allowedAttributes['a'], ...['class', 'data-width', 'data-height']];
}

Vue.use(VueSanitize, defaults);

Vue.config.ignoredElements = ignoredElements;
Vue.filter('stringify', (input) => JSON.stringify(input));
// HTTP
Vue.prototype.$http = require('./http').default;

// ERROR LOGGING
Vue.config.errorHandler = function(err, vm, info) {
  // handle all vue errors
  log({ message: err, source: ' VUE', severity: SEVERITY.ERROR });
};

window.onerror = function(message, file, line, col, error) {
  // handle all js errors
  log({ message: error.message, line, source: ' JS', severity: SEVERITY.ERROR });
  return false;
};

window.onunhandledrejection = (event) => {
  // handle uncaught promises
  if (event?.reason?.response?.status !== 200 && event?.reason?.request?.responseURL?.indexOf('Logs') > -1) {
    return;
  }

  log({ message: event?.reason, source: ' JS', severity: SEVERITY.ERROR });
};

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
